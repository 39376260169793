@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.main-cols {
	&.layout-column {
		display: block;
	}
}

.gm-style .gm-style-iw-c {

	@media (max-width:450px) {
		max-width: 280px !important;
	}
}

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.container-fluid {
		max-width: 1440px;
		width: 100%;

		.flex-row {
			display: flex;

			#sidebar {
				position: sticky;
				top: 30px;
			}

			.map-col {
				padding-bottom: 90px;
			}
		}

		.list-view {
			.map-col {
				display: block;
				padding-top: 30px;
			}
		}
	}

	.searchBanner {
		background: url(RESOURCE/img/search-banner-new-bg-1.jpg) no-repeat center;
		background-size: cover;
		padding: 75px 0px;

		.show-map-list {
			float: right;

			a {
				display: inline-block;
				background: #ffffff;
				border: solid 2px #ffffff;
				padding: 10px 45px;
				text-transform: uppercase;
				font-size: 18px;
				color: #008a97;
				border-radius: 0px;
				margin: 8px 0px;
				text-decoration: none
			}
		}

		.form-control {
			background: rgba(255, 255, 255, 0.9);
			border-radius: 0px;
			border: none;
			font-size: 16px;
			line-height: 24px;
			color: #008a97;
			margin: 8px 0px;
			position: relative;

			&.datePickerField {
				background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/calendar-icon.png) no-repeat center left 12px;
				padding: 10px 16px 10px 50px;
			}

			&.guestField {
				background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/guest-icon.png) no-repeat center left 12px;
				padding: 10px 16px 10px 50px;
			}

			&.regionField {
				background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/addree-icon.png) no-repeat center left 12px;
				padding: 10px 16px 10px 50px;
			}
		}

		.daterange {
			.form-control {}
		}

		.select-group {
			select.form-control {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				position: relative;
				color: #008a97;
			}

			.custom-select {
				position: relative;

				&:after {
					content: "";
					font-family: fontawesome;
					content: "\f107";
					pointer-events: none;
					position: absolute;
					top: 13px;
					right: 15px;
					font-size: 15px;
					color: #008a97;
				}
			}

		}

		.searchBtn {
			.btn {
				display: inline-block;
				background: #008a97;
				border: solid 2px #008a97;
				padding: 10px 60px;
				text-transform: uppercase;
				font-size: 18px;
				color: #ffffff;
				border-radius: 0px;
				margin: 8px 0px;
			}
		}

		.adv-filt-form {
			background: rgba(255, 255, 255, 0.8);
			padding: 15px 20px;
			margin-top: 10px;

			.form-control {
				border: 1px solid #008a97;
				padding: 10px 15px;
				font-size: 15px;
				color: #008a97;
				border-radius: 0px;
				line-height: 24px;
				color: #222222;
				margin: 8px 0px;
				height: auto;
			}

			.form-group {
				margin: 0px;

				.checkbox,
				.radio {
					margin-top: 5px;
					margin-bottom: 5px;
				}
			}
		}

		@media screen and (max-width: 639px) {
			padding: 45px 10px;
			padding-top: 130px;

			.col-xs-6,
			.col-xs-12 {
				padding: 0px 8px;
			}

			.form-control {
				font-size: 15px;
			}

			.searchBtn {
				.btn {
					width: 100%;
					font-size: 16px;
				}
			}

			.show-map-list {
				width: 100%;
				text-align: center;

				a {
					width: 100%;
					font-size: 16px;
				}
			}
		}

		@media screen and (max-width: 639px) {

			.col-xs-6,
			.col-xs-12 {
				width: 100%;
			}
		}
	}

	.list-view {
		.map-col {
			.affix {
				@media (min-width:993px) {
					position: fixed !important;
					top: 40px;
					max-width: 360px;
				}
			}
		}
	}

	.map-view {

		.map-col {
			margin-top: 20px;
			margin-bottom: 20px;

			.affix,
			.affix-bottom {
				@media (min-width:993px) {
					position: relative !important;
					top: 0 !important;
					width: 100%;
					padding-right: 0;
					max-width: inherit;
				}
			}
		}
	}


	.map-col {
		.affix {
			@media (max-width:992px) {
				position: relative !important;
			}

			@media (min-width:993px) {
				width: 33.33%;
				max-width: inherit;
				padding-right: 30px;
				top: 60px;
			}

			@media (min-width:1440px) {
				position: fixed !important;
				top: 50px;
				width: 100%;
				max-width: 450px;
			}

		}

		.affix-bottom {
			@media (max-width:992px) {
				position: relative;
				top: inherit !important;
			}
		}
	}

	.result-pane {
		width: 100%;
		padding: 0;

		color: #565a5c;

		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 35px 5px 0;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;


			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.results {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
		}

		.pagination-panel {
			text-align: center;

			li {

				a,
				span {
					border-radius: 0px !important;
				}
			}

			.active {
				a {
					background: #f39200;
					color: #ffffff;
					border: solid 1px #f39200;
					font-weight: 600;
				}
			}
		}

	}

	.map-view {


		.result-pane {
			@media (min-width:993px) {
				display: none;
			}
		}

		.list-content {
			@media (min-width:993px) {
				display: none;
			}
		}

		.map-col {
			width: 100%;
		}
	}

	.map-pane {
		padding: 25px 0 0;

		.map-main {
			height: 100%;

			.flex {
				height: 100%;

				>div {
					height: calc(100% - 60px);
				}
			}

			&.no-map {
				height: 100%;

				.flex {
					height: 100%;

					>div {
						height: 100%;
					}
				}
			}
		}

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}

		.mapnote {
			font-size: 16px;
			padding: 15px 0 20px;
			background: #fff;

			@media (max-width: 767px) {
				padding: 0 015px;
				font-size: 13px;

			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				font-size: 14px;
				padding-right: 10px;
				min-height: 135px;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				font-size: 13px;
			}
		}

		.mapconsent {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			background: rgba(26, 121, 187, 0.15);
			flex-direction: column;
			padding: 0 15px;

			.doc-box {}

			.mapconsent-button {
				background: var(--primary);
				color: #fff;
				border-radius: 5px;
				padding: 0 15px 0 15px;
				height: 36px;
				display: block;
				line-height: 35px;

				&:hover {
					background: var(--secondary);
				}
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

		.search-result-properties {
			text-align: left;
			-webkit-box-align: center;
			align-items: center;
			-webkit-box-pack: start;
			justify-content: flex-start;
			min-height: 25px;
			margin-bottom: 10px;
			width: 100%;
			flex-wrap: wrap;

			.properties-block {
				display: flex;
				align-content: center;
				justify-content: center;
				margin: 4px 15px 0 0;

				i {
					display: flex;
					height: auto;
					margin: 3px 5px 0 0;
				}
			}
		}

		.unitImg {
			position: relative;
			border-bottom: solid 5px #008a97;

			.serviceList {
				position: absolute;
				left: 10px;
				top: 10px;

				span {
					background: rgba(0, 0, 0, 0.6);
					padding: 5px 8px;
					font-size: 15px;
					color: #ffffff;
					display: inline-block;
					vertical-align: top;
					margin-right: 5px;
					border-radius: 4px;
				}
			}

			.fav-icon {

				position: absolute;
				right: 10px;
				top: 10px;
				text-align: center;

				.fa {
					cursor: pointer;
					background: rgba(0, 0, 0, 0.6);
					color: #ffffff;
					font-size: 18px;
					line-height: 30px;
					width: 30px;
					text-align: center;
					border-radius: 4px;

					&.fa-heart {
						background: rgba(255, 255, 255, 0.9);
						color: #f39200;
					}
				}
			}

			img {
				width: 100%;
				max-width: 100%;
			}
		}

		.unitListDet {
			float: left;
			width: 100%;
			position: relative;
			padding: 0px 0px 0px 0px;
			text-align: left;

			.region {
				float: left;
				width: 100%;
				padding-right: 100px;
				display: none;

				.unitType {
					padding: 3px 0px;
					font-size: 12px;
					line-height: 20px;
					color: #222222;
					font-weight: 600;
					text-align: left;

					.fa {
						font-size: 7px;
						vertical-align: middle;
						margin: 0px 3px;
					}
				}

				.searchStrand {
					position: absolute;
					top: 0px;
					right: 0px;
					background: #008a97;
					padding: 3px 10px;
					color: #ffffff;
					font-size: 12px;
					line-height: 20px;
				}
			}

			.unitTitle {
				float: left;
				width: 100%;
				font-size: 14px;
				color: #225ca8;
				line-height: 22px;
				font-weight: 600;
				text-transform: uppercase;
				margin: 10px 0px 5px 0px;
			}

			.rentRate {
				float: left;
				width: 100%;
				font-size: 15px;
				color: #222222;
				margin-bottom: 10px;
				margin-top: 10px;

				strong {
					font-size: 18px;

				}

				.fal {
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
				}
			}

			.ratingsTxt {
				float: left;
				width: 100%;
				margin: 0 0 10px;

				.ratings {
					float: left;
				}

				.fa-star {
					color: #f39200;
				}

				span {
					margin-left: 10px;
				}

				.count {
					font-size: 14px;
					color: #676767;

					@media (max-width:450px) {
						font-size: 12px;
						margin-left: 5px;
					}
				}
			}
		}

	}

	.map-view {
		.map-pane {
			.flex {
				height: 100%;

				>div {
					height: calc(100% - 100px);
				}
			}

		}
	}

	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}


	.search-result-properties {
		display: flex;
		text-align: center;
		justify-content: center;

		.ft-icon {
			text-align: center;
			display: inline-flex;
			align-items: center;
			color: #fff;
			border: 1px solid #fff;
			padding: 5px 10px;
			margin: 10px 5px;

			.number {
				margin-left: 5px;
			}
		}

	}


	@media (max-width:992px) {

		.list-view {
			.map-pane {
				display: none;
			}

			.result-pane {
				display: block;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}

			.result-pane {
				display: none;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;
			height: 100%;
			z-index: 99999;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}

	@media (max-width:479px) {
		.list-view {
			.result-pane {
				padding: 0px;
			}
		}
	}


	.results {
		.unit-box {
			border: 1px solid #e0dfdf;
			position: relative;
			float: left;
			width: 100%;
			margin-bottom: 30px;

			.rent-rate {
				padding: 5px 10px;
				color: #222222;
				font-weight: bold;
			}

			.alternatives {
				.altheading {
					color: red;
				}

				.li-bullet {
					padding-left: 0;
					margin-top: 10px;
					list-style: none;
				}
			}



			.unit-title {
				color: #222222;
				padding: 10px;
				width: 100%;
				z-index: 2;
			}

			.unitImg {
				position: relative;
				border-bottom: solid 5px #008a97;

				.serviceList {
					position: absolute;
					left: 10px;
					top: 10px;

					span {
						background: rgba(0, 0, 0, 0.6);
						padding: 5px 8px;
						font-size: 15px;
						color: #ffffff;
						display: inline-block;
						vertical-align: top;
						margin-right: 5px;
						border-radius: 4px;
					}
				}

				.owl-carousel {
					.owl-prev {
						position: absolute;
						top: calc(50% - 10px);
						z-index: 1;
						color: #fff;
						font-size: 40px;
						line-height: 20px;
						left: 10px;
						outline: none;
					}

					.owl-next {
						position: absolute;
						top: calc(50% - 10px);
						z-index: 1;
						color: #fff;
						font-size: 40px;
						line-height: 20px;
						right: 10px;
						outline: none;
					}
				}

				.fav-icon {

					position: absolute;
					right: 10px;
					top: 10px;
					text-align: center;
					z-index: 1;

					.fa {
						cursor: pointer;
						background: rgba(0, 0, 0, 0.6);
						color: #ffffff;
						font-size: 18px;
						line-height: 30px;
						width: 30px;
						text-align: center;
						border-radius: 4px;

						&.fa-heart {
							background: rgba(255, 255, 255, 0.9);
							color: #f39200;
						}
					}
				}

				.mapMarker {
					background: rgba(0, 0, 0, 0.6);
					position: absolute;
					right: 45px;
					top: 10px;
					color: #ffffff;
					font-size: 18px;
					line-height: 30px;
					width: 30px;
					text-align: center;
					border-radius: 4px;

					.fa {
						cursor: pointer;
					}
				}

				.map-marker {
					position: absolute;

					bottom: 0;
					top: inherit;
					right: 0;

					i {
						color: var(--whitecolor);
					}
				}

				img {
					width: 100%;
				}
			}

			.unitListDet {
				float: left;
				width: 100%;
				position: relative;
				padding: 0px 15px 15px 15px;
				min-height: 250px;

				.region {
					float: left;
					width: 100%;
					padding-right: 100px;
					display: none;

					.unitType {
						padding: 8px 0px;
						font-size: 15px;
						line-height: 20px;
						color: #222222;
						font-weight: 600;

						.fa {
							font-size: 7px;
							vertical-align: middle;
							margin: 0px 3px;
						}
					}


					.searchStrand {
						position: absolute;
						top: 0px;
						right: 0px;
						background: #008a97;
						padding: 6px 12px;
						color: #ffffff;
						font-size: 14px;
						line-height: 20px;
					}
				}

				.search-unit-title {
					position: relative;

					.map-marker {
						position: absolute;
						right: -15px;
						font-size: 25px;
						top: 0;
						width: 40px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						z-index: 1;
						cursor: pointer;
					}
				}

				.unitTitle {
					float: left;
					width: 100%;
					font-size: 18px;
					color: #225ca8;
					line-height: 24px;
					min-height: 48px;
					font-weight: 600;
					text-transform: uppercase;
					margin: 12px 0 10px;
					padding: 0 40px 0 0;

					@media (max-width: 992px) {
						font-size: 15px;
					}

				}

				.rentRate {
					float: left;
					width: 100%;
					font-size: 15px;
					color: #222222;
					margin-bottom: 10px;
					min-height: 25px;

					strong {
						font-size: 18px;

					}

					.fal {
						font-family: 'FontAwesome';
						font-weight: 900;
					}
				}

				.ratingsTxt {
					float: left;
					width: 100%;
					margin: 0px 0px 0px 0px;
					min-height: 30px;

					@media (max-width:450px) {
						font-size: 12px;
					}

					.ratings {
						float: left;
					}

					.fa-star,
					.fa-star-half,
					.fa-star-half-alt {
						color: #f39200;
					}

					span {
						margin-left: 10px;
					}

					.count {
						font-size: 14px;
						color: #676767;

						@media (max-width:450px) {
							font-size: 12px;
						}
					}
				}
			}

			.unit-cont-bl {
				background-color: var(--whitecolor);
				padding: 10px 15px;
				height: 60px;
				overflow: hidden;
				margin-bottom: 10px;
			}

			.unit-feat {
				background-color: var(--primarycolor);
				width: 100%;
				text-align: left;
				align-items: center;
				justify-content: flex-start;
				min-height: 45px;
				margin-bottom: 20px;
				flex-wrap: wrap;

				.properties-block {
					display: flex;
					align-content: center;
					justify-content: center;
					margin: 0 15px 0 0;

					i {
						display: flex;
						height: auto;
						margin: 3px 5px 0 0;
					}
				}

			}
		}
	}


	.map-pane {
		@mixin flex;
		height: 80vh;

		@media (max-width:992px) {
			z-index: 50;
			padding: 0;
		}
	}

	@media (min-width:768px) {
		.result-pane {
			.more-btn-panel {
				display: none;
			}
		}
	}
}

@media (max-width: 767px) {
	.vo-search-body {
		.result-pane {
			.results {
				display: block;
			}
		}
	}
}

@media all and (min-width: 993px) and (max-width: 1400px) {
	.vo-search-body {
		.list-view {
			.map-col {
				.affix {
					max-width: 25%;
				}
			}
		}
	}
}
@import "common.css";

.unit-view {
	float: left;
	width: 100%;
	padding: 60px 0px;
	padding-top: 120px;

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.v-img-gallery {
		margin-top: 20px;
	}

	.search-section {
		.searchbox {
			margin: 1px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.title-img {

		position: relative;
		width: 100%;
		/*height: 700px;*/

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}



	.main-section {
		position: relative;
		max-width: 1200px;

		h2 {
			margin-top: 40px;
			font-size: 22px;
			font-weight: 700;
			color: #008a97;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #f39200;
			}
		}

		.main-col {

			position: static;

			.page-header {
				margin: 0px 0px 20px 0px;
				padding: 0px 0px 10px 0px;

				h1 {
					margin: 0px;
					padding: 0px;
					font-size: 30px;
					line-height: 40px;
					color: #008a97;
					font-weight: 700;
				}

				@media screen and (max-width: 639px) {
					h1 {
						font-size: 24px;
						line-height: 32px;
					}
				}
			}

			.owl-carousel {
				.owl-item {
					img {
						height: 550px;
						object-fit: cover;
					}
				}

				.owl-prev {
					position: absolute;
					top: calc(50% - 10px);
					z-index: 1;
					color: #fff;
					font-size: 60px;
					line-height: 30px;
					left: 10px;
					outline: none;
				}

				.owl-next {
					position: absolute;
					top: calc(50% - 10px);
					z-index: 1;
					color: #fff;
					font-size: 60px;
					line-height: 30px;
					right: 10px;
					outline: none;
				}
			}

			.stars {
				margin-top: 10px;

				.fa-star {
					color: #f39200;
				}
			}

			.description {
				padding: 15px 0px 5px 0px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {

				@mixin columnlayout 2;

				.prop {
					margin: 5px 0px;
				}

				.fa-check {
					color: #f39200;
					font-size: 15px;
					margin-right: 6px;
				}

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		.sidebar-unit {
			.preiseBuchen {
				margin-bottom: 25px;

				.info-col-header {
					background: #008a97;

					h2 {
						background: transparent;
						color: #ffffff;
					}
				}

				.form-control {
					background: rgba(255, 255, 255, 0.9);
					border-radius: 0px;
					border: none;
					font-size: 16px;
					line-height: 24px;
					color: #008a97;
					border: 1px solid #008a97;
					margin: 8px 0px;
					position: relative;
					height: auto;

					&.datePickerField {
						background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/calendar-icon.png) no-repeat center left 12px;
						padding: 10px 16px 10px 50px;
					}

					&.guestField {
						background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/guest-icon.png) no-repeat center left 12px;
						padding: 10px 16px 10px 50px;
					}

					&.regionField {
						background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/addree-icon.png) no-repeat center left 12px;
						padding: 10px 16px 10px 50px;
					}
				}

				.btn.btn-default.btn-lg,
				.btn.btn-sm {
					border-radius: 0px;
				}

				.btn.btn-go {
					background: #008a97;
					border: solid 1px #008a97;
					padding: 12px 22px;
					font-size: 16px;
					border-radius: 0px;
					font-weight: 700;
				}
			}

			.fav-section {
				margin-bottom: 25px;
			}

			.contact-form {
				h2 {
					margin-top: 25px;
				}

				.form-group {
					label {
						text-align: left;
						display: block;
						font-weight: 700;
						color: #222222;
						margin: 0px 0px 5px 0px;

						.mandatory {
							color: #ff0000;
							font-size: 15px;
							font-weight: normal;
						}
					}

					.form-control {
						border-radius: 0px;
					}
				}

				.btn.btn-go {
					background: #008a97;
					border: solid 1px #008a97;
					padding: 12px;
					border-radius: 0px;
					font-weight: 700;
				}

				.checkbox {
					.privacyCheck {
						display: inline-block;

						&:before {
							top: 3px;
						}

						&:before {
							top: 2px;
						}
					}
				}
			}
		}

		.info-col {

			max-width: 800px;

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

				.flow-buttons {
					button {
						margin-left: 20px;
					}
				}
			}
		}

		.info-col {

			/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

			.info-col-header {
				height: 40px;
				padding: 1px;
				background-color: rgba(60, 63, 64, 0.9);
				color: #fff;
				text-align: center;

				h2 {
					margin-top: 10px;
					font-size: 18px;
				}
			}

			.info-col-main {
				border: 1px solid #888;
				border-bottom: none;
				width: 100%;
				padding: 15px 15px 1px 15px;
			}

			.subprice {
				width: 100%;
				border: 1px solid #888;
				border-top: none;
			}

			.fav-section {
				margin-top: 20px;
				border-bottom: 1px solid #888 !important;
				padding: 15px;

				.fa-heart {
					color: #f39200;
				}
			}

			@media screen and (max-width: 767px) {
				margin-top: 30px;
			}

		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map.no-map {

			>div {
				height: 100% !important;
			}
		}

		.unit-map {
			height: 500px;
			width: 100%;


			>div {
				height: calc(100% - 150px);

				@media (max-width:767px) {
					height: calc(100% - 120px);
				}

			}


			.mapnote {
				font-size: 16px;
				padding: 15px 0 20px;
				background: #fff;

				@media (max-width:992px) {
					font-size: 14px;
				}
			}


			.mapconsent {
				height: 100%;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				text-align: center;
				align-items: center;
				justify-content: center;
				padding: 15px;
				background: rgba(26, 121, 187, .15);

				@media (max-width: 992px) {
					padding: 15px;
				}

				@media all and (min-width: 993px) and (max-width: 1199px) {
					padding: 15px;
				}

				a {
					background: #ff9c3c;
					background: var(--primary);
					color: #fff;
					border-radius: 5px;
					padding: 0 15px;
					height: 36px;
					display: block;
					line-height: 35px;

					&:hover {
						background: var(--secondary);
					}
				}
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}

	.datepicker-trigger {


		.asd__day--not-available {
			background: var(--not_available) !important;
			opacity: 1 !important;
		}

		.asd__day--arrival {
			background: linear-gradient(135deg, var(--available) 50%, var(--not_available) 50%) !important;
			opacity: 1 !important;
		}

		.asd__day--departure {
			background: linear-gradient(135deg, var(--not_available) 50%, var(--available) 50%) !important;
			opacity: 1 !important;
		}

		.asd__day--disabled.asd__day--in-range {
			background: var(--selection_range) !important;
			opacity: 1 !important;
			border: 1px double var(--selected_border) !important;
		}

		.asd__day--enabled.asd__day--in-range {
			background: var(--selection_range) !important;
			color: white !important;
			opacity: 1 !important;
			border: 1px double var(--selected_border) !important;
		}

		.asd__day--disabled.asd__day--hovered {
			background: var(--selection_range) !important;
			opacity: 1 !important;
			border: 1px double var(--selected_border) !important;
		}

		.asd__day--enabled.asd__day--hovered {
			background: var(--selection_range) !important;
			opacity: 1 !important;
			border: 1px double var(--selected_border) !important;
		}



		.asd__day--enabled {
			background-color: var(--available) !important;
			color: var(--text_color) !important;

			&.asd__day--disabled {
				background-color: var(--disabled) !important;
				text-decoration: underline;

				&.asd__day--selected {
					background-color: var(--selected) !important;
				}

				&.asd__day--in-range {
					background: var(--selection_range) !important;
					opacity: 1 !important;
					border: 1px double var(--selected_border) !important;
				}

				&.asd__day--hovered {
					background: var(--selection_range) !important;
					opacity: 1 !important;
					border: 1px double var(--selected_border) !important;
				}
			}

			&.asd__day--arrival {
				&:hover {
					background-color: var(--selected) !important;
				}
			}

			&.asd__day--not-available {
				background: var(--not_available) !important;
				opacity: 1 !important;
			}
		}

		.asd__day--enabled:not(.asd__day--disabled) {
			&:hover {
				background: var(--selected) !important;
				opacity: 1 !important;
				color: white !important;
			}
		}

		.asd__day--selected {
			background: var(--selected) !important;
			color: white !important;
			opacity: 1 !important;
			border: 1px double var(--selected_border) !important;

			/*Arrow display css start*/
			&.asd__selected-date-one {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					border-left: 12px solid transparent;
					border-top: 19px solid var(--selection_range);
					border-bottom: 20px solid var(--selection_range);
					right: 0px;
				}
			}

			&.asd__selected-date-two {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					border-right: 12px solid transparent;
					border-top: 19px solid var(--selection_range);
					border-bottom: 20px solid var(--selection_range);
					left: 0px;
				}
			}

			/*Arrow display css end*/
		}


	}




	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header h1 {
			font-size: 24px;
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}




}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}
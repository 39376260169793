.blog {
	padding: 0 0 50px 0;

	.blog-entry {
		color: #333;
		display: block;
		text-decoration: none;
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px dotted #7c949f;

		.blog-header {
			margin-bottom: 10px;
		}

		h2 {
			font-size: 24px;
			margin-top: 0;
		}




	}

}

.blogentry {
	padding: 0 0 50px 0;

	.tag-box {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px 5px 0;

			a {
				background-color: #ddd;
				padding: 5px;
				display: block;

			}
		}
	}
}
:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 768px;

	/*Calendar colors*/
	--not_available: #F8CCCF;
	--disabled: #ECFAEC;
	--available: #C6F9C6;
	--selected: #f39200;
	--selection_range: #EFA83C;
	--selected_border: #fff;
	--text_color: #333;
	--primary: #008a97;
	--secondary: #f39200;
}
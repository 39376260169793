@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.doc-box {
	text-align: left;
}

@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}

/*@font-face {
	font-family: 'jollyregular';
	src: url('RESOURCE/fonts/jolly.eot');
	src: url('RESOURCE/fonts/jolly.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/jolly.woff2') format('woff2'),
		url('RESOURCE/fonts/jolly.woff') format('woff'),
		url('RESOURCE/fonts/jolly.ttf') format('truetype'),
		url('RESOURCE/fonts/jolly.svg#jollyregular') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'jollybold';
	src: url('RESOURCE/fonts/jolly_bold.eot');
	src: url('RESOURCE/fonts/jolly_bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/jolly_bold.woff2') format('woff2'),
		url('RESOURCE/fonts/jolly_bold.woff') format('woff'),
		url('RESOURCE/fonts/jolly_bold.ttf') format('truetype'),
		url('RESOURCE/fonts/jolly_bold.svg#jollybold') format('svg');
	font-weight: normal;
	font-style: normal;

}*/

body {
	/*font-family: 'jollyregular';*/
	font-size: 15px;
	color: #222222;
	font-weight: normal;
	font-style: normal;
}

a {
	color: #008a97;
}

.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}

.btn.btn-primary {
	background: #008a97;
	border: 1px solid #008a97;
	color: #fff;

	&:hover {
		background: #f39202;
		border: 1px solid #f39202;
	}
}

.cookie-dlg {
	z-index: 99999999 !important;
}

.box.full-xxs {
	z-index: 99999999 !important;
}

.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.popover {
	width: 220px;
	left: 0 !important;

	.popover-title {
		color: #008a97;
	}
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__wrapper {
	z-index: 9999999 !important;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {


	.asd__day--not-available {
		background: var(--not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(135deg, var(--available) 50%, var(--not_available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--selection_range) !important;
		color: white !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}



	.asd__day--enabled {
		background-color: var(--available) !important;
		color: var(--text_color) !important;

		&.asd__day--disabled {
			background-color: white !important;
			opacity: 0.8 !important;
			text-decoration: underline;

			&.asd__day--selected {
				background-color: var(--selected) !important;
			}

			&.asd__day--in-range {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 1px double var(--selected_border) !important;
			}

			&.asd__day--hovered {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 1px double var(--selected_border) !important;
			}
		}

		&.asd__day--arrival {
			&:hover {
				background-color: var(--selected) !important;
			}
		}

		&.asd__day--not-available {
			background: var(--not_available) !important;
			opacity: 1 !important;
		}
	}

	.asd__day--enabled:not(.asd__day--disabled) {
		&:hover {
			background: var(--selected) !important;
			opacity: 1 !important;
			color: white !important;
		}
	}

	.asd__day--selected {
		background: var(--selected) !important;
		color: white !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;

		/*Arrow display css start*/
		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				left: 0px;
			}
		}

		/*Arrow display css end*/
	}


}

.legend-cal {
	text-align: center;
	padding: 0;
	margin: 15px 0;
	display: flex;

	p {
		margin: 0;
		padding: 8px 10px 0 0;
	}

	ul {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		//justify-content: center;
		padding: 0px;
		margin: 0px;
	}

	li {
		display: inline-flex;
		align-items: center;
		min-height: 30px;
		padding-right: 15px;
		margin: 5px 0;

		.day {
			width: 30px;
			height: 30px;
			background-color: #7e7975;
			display: inline-block;
			margin: 0 10px;

			&.day-free {
				background: rgba(0, 255, 0, .2);
			}

			&.day-full {
				background: rgba(221, 0, 17, .2);
			}

			&.day-start {
				background: rgb(87, 87, 86, 1);
				background: linear-gradient(136deg, rgba(221, 0, 17, .2) 50%, rgba(0, 255, 0, .2) 50%);
			}

			&.day-end {
				background: rgb(170, 126, 55);
				background: linear-gradient(136deg, rgba(0, 255, 0, .2) 50%, rgba(221, 0, 17, .2) 50%);
			}

			&.day-disabled {
				background: var(--disabled);
			}

			&.day-selected {
				background: var(--selected);
			}
		}
	}
}

.form-group select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	position: relative;
	background: url(RESOURCE/img/dropdown-icon.png) no-repeat center right 12px;

}

.form-group .checkbox {
	padding-left: 20px
}

.form-group .checkbox label {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 5px
}

.form-group .checkbox label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 17px;
	height: 17px;
	left: 0;
	margin-left: -20px;
	border: 1px solid #ccc;
	border-radius: 3px;
	background-color: #fff;
	-webkit-transition: border .15s ease-in-out, color .15s ease-in-out;
	-o-transition: border .15s ease-in-out, color .15s ease-in-out;
	transition: border .15s ease-in-out, color .15s ease-in-out
}

.form-group .checkbox label::after {
	display: inline-block;
	position: absolute;
	width: 16px;
	height: 16px;
	left: .5px;
	top: 1.5px;
	margin-left: -20px;
	padding-left: 3px;
	padding-top: 1px;
	font-size: 11px;
	color: #555
}

.form-group .checkbox input[type="checkbox"] {
	opacity: 0;
	z-index: 1
}

.show-mob {
	display: none;

	@media (max-width:767px) {
		display: block;
	}
}

.form-group .checkbox input[type="checkbox"]:focus+label::before {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px
}

.form-group .checkbox input[type="checkbox"]:checked+label::after {
	font-family: 'FontAwesome';
	content: "\f00c";
	top: 0px;
}

.form-group .checkbox input[type="checkbox"]:disabled+label {
	opacity: .65
}

.form-group .checkbox input[type="checkbox"]:disabled+label::before {
	background-color: #eee;
	cursor: not-allowed
}

.form-group .checkbox.checkbox-circle label::before {
	border-radius: 50%
}

.form-group .checkbox.checkbox-inline {
	margin-top: 0
}

.form-group .checkbox input[type="checkbox"]:checked+label::before {
	background-color: #f39200;
	border-color: #f39200
}

.form-group .checkbox input[type="checkbox"]:checked+label::after {
	color: #fff
}


/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}
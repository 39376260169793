.mainFooter {
	float: left;
	width: 100%;
	background: #008a97;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	padding: 50px 0 30px 0px;
	border: none;

	.col {
		float: left;
		width: 50%;
	}

	h6 {
		font-size: 18px;
		margin-top: 0px;
	}

	.footerAddres {
		text-align: center;

		h6 {
			margin-bottom: 25px;
		}
	}

	.footerLinks {
		padding: 0 15px 0 100px;

		h6 {
			margin-bottom: 40px;
		}

		.socialIcons {
			.fa {
				font-size: 30px;
				color: #ffffff;
				margin-right: 10px;
			}
		}

		.col2 {
			ul {
				margin: 0px;
				padding: 0px;

				li {
					list-style: none;
					margin-bottom: 15px;

					a {
						color: #ffffff;
						text-decoration: none;
					}
				}
			}
		}
	}

	.contact-section {
		padding: 40px 10px 80px 10px;

		p {
			color: #555;
			margin-bottom: 20px;
		}

		p.lead {
			font-size: 24px;
			margin-bottom: 10px;
		}

		.contact {
			font-size: 24px;

			.fa {
				margin: 10px;
				font-size: 36px;
			}

			color: @introcolor;
		}
	}


	footer {
		width: 100%;
		padding: 20px;
		text-align: center;

		.company-name,
		.footer-link {
			font-family: "Raleway", sans-serif;
			font-size: 18px;
			background-color: @navbgcolor;
			color: #888;
		}


		.footer-link {
			color: #888;
		}
	}

	.dlg.show {
		font-size: 16px;
		color: #222222;
		z-index: 9999999;

		a {
			colo: #008a97;
		}

		h4 {
			text-align: left;
		}
	}

	@media screen and (max-width: 1200px) {
		font-size: 14px;

		h6 {
			font-size: 15px;
			line-height: 24px;
		}

		.footerLinks {
			.col1 {
				width: 45%;
			}

			.col2 {
				width: 55%;
			}
		}
	}

	@media screen and (max-width: 989px) {

		.footerLinks,
		.footerTime,
		.footerAddres {
			width: 100%;
			padding: 0 15px;
		}

		.footerAddres {
			margin: 20px 0px;
			text-align: left;
		}
	}

	@media screen and (max-width: 989px) {

		.footerLinks {

			.col1,
			.col2 {
				width: 100%;
			}
		}
	}

}
.booking-view {
	float: left;
	width: 100%;
	padding: 60px 0px;
	padding-top: 120px;

	.Reiseversicherung {
		margin: 20px 0 0 0;

		a {
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #008a97;
			text-decoration: none;
			background: #f4f4f4;
			padding: 10px;
			justify-content: center;
			max-width: 240px;
			border: 2px solid rgb(0 0 0 / 18%);
			width: 100%;

			&:hover {
				box-shadow: 0px 2px 10px 3px rgb(0 0 0 / 30%);
			}

			img {
				margin: 0 10px 0 0;
				width: 70px;
				height: auto;
			}
		}
	}

	.page-header {
		margin-top: 0px;

		h1 {
			margin-top: 0px;
			color: #008a97;
			font-weight: 600;
		}

		span {
			color: #008a97;
		}

		.fa-check {
			color: #f39200;
		}
	}

	.cart-details {
		h4 {
			font-size: 20px;
			font-weight: 600;
			color: #008a97;
		}

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}

		.price-table {
			.btn {
				display: none;
			}
		}

		.price-details-btn {
			color: #008a97;
		}
	}

	.pd-form {

		label.lrequired {
			font-weight: 700;
		}

	}

	.form-group {
		label {
			.mandatory {
				color: #ff0000;
				font-weight: normal;
				font-size: 15px;
			}
		}
	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			label {
				font-weight: 700;
			}

			.label-agb {
				.doc-box {
					top: 2px;
				}
			}

			.doc-box {
				position: relative;
				top: 1px;
			}

			.checkbox {
				label {
					&:before {
						top: 2px;
					}

					&:after {
						top: 2px;
					}
				}
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;

		&.btn.btn-go {
			background: #008a97;
			border: solid 1px #008a97;
			padding: 12px 22px;
			font-size: 16px;
			border-radius: 0px;
			font-weight: 700;
			color: #ffffff;
		}

		&.btn.btn-default {
			font-size: 16px;
			padding: 12px 22px;
			border-radius: 0px;
			color: #222222;
		}
	}


}
@import "setup.css";

.indexpage {

	.homeBanner {
		float: left;
		width: 100%;
		/* background: url(RESOURCE/img/home-banner2.jpg) no-repeat center; */
		background-size: cover;
		position: relative;
		min-height: 500px;
		height: 80vh;

		.banner {
			height: 100%;
		}

		.owl-carousel {
			height: 100%;
			z-index: 0;

			.owl-nav {
				display: none !important;
			}

			.owl-stage-outer {
				height: 100%;

				.owl-stage {
					height: 100%;

					.owl-item {
						height: 100%;

						.item {
							height: 100%;
						}

						.banner1 {
							background: url(RESOURCE/img/home-banner2.jpg) no-repeat center;
						}

						.banner2 {
							background: url(RESOURCE/img/banner-2.jpg) no-repeat center;
						}

						.banner3 {
							background: url(RESOURCE/img/banner-3.jpg) no-repeat center;
						}
					}
				}
			}
		}



		.searchbar {
			position: absolute;
			left: 0px;
			right: 0px;
			bottom: 100px;

			.homeSearch {
				float: left;
				width: 100%;
				background: #ffffff;
				padding: 12px;
			}

			.searchbox {
				padding: 5px;

				.form-control {
					background: #ffffff;
					border: solid 1px #008a97;
					padding: 15px 18px;
					font-size: 15px;
					color: #008a97;
					box-shadow: none;
					border-radius: 0px;
					font-weight: 600;
					height: auto;

					&.datePickerField {
						background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/calendar-icon.png) no-repeat center left 12px;
						padding: 15px 16px 15px 50px;
					}

					&.guestField {
						background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/guest-icon.png) no-repeat center left 12px;
						padding: 15px 16px 15px 50px;
					}

					&.regionField {
						background: rgba(255, 255, 255, 0.9) url(RESOURCE/img/addree-icon.png) no-repeat center left 12px;
						padding: 15px 16px 15px 50px;
					}
				}

				.search-btn {
					display: block;
					background: #008a97;
					border: solid 1px #008a97;
					padding: 15px 15px;
					font-size: 15px;
					color: #ffffff;
					box-shadow: none;
					font-weight: bold;
					border-radius: 0px;

					&:hover {
						background: #f39202;
						border: 1px solid #f39202;
					}
				}
			}

			.search-checkbox {
				display: flex;
				width: 100%;
				margin: 0;
				padding: 0 5px;

				.form-group {
					margin: 0;

					.checkbox {
						margin: 5px 0 5px 0;
					}
				}
			}
		}

		@media (max-width:767px) {
			.searchbar {
				left: 15px;
				right: 15px;
			}
		}
	}

	.homeBoxes {
		float: left;
		width: 100%;
	}

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}



	.iheader {

		min-height: 500px;

		@media (min-width:1200px) {
			min-height: 700px;
		}

		background: var(--introImg) no-repeat center;
		-webkit-background-size: cover;
		background-size: cover;

		position: relative;


		.intro {
			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;

				margin-bottom: 10px;
				padding-bottom: 0px;

				h1,
				h2 {
					text-shadow: -3px 0 3px rgba(0, 0, 0, .6), 0 3px 3px rgba(0, 0, 0, .6), 3px 0 3px rgba(0, 0, 0, .6), 0 -3px 3px rgba(0, 0, 0, .6);
				}

				h1 {

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 60px 0 0;
					text-shadow: 0 0 10px #000;

				}

				h2 {
					font-size: 24px;

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 20px 0 0;
					text-shadow: 0 0 10px #000;

				}

			}

		}


	}

	.contact-section {
		background-color: #fff !important;
	}

	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}
}
@import "setup.css";

body {
	padding-top: 155px;

	@media screen and (max-width: 1200px) {
		padding-top: 170px;
	}

	@media screen and (max-width: 978px) {
		padding-top: 210px;
	}

	@media screen and (max-width: 767px) {
		padding-top: 0;
	}
}

.mainHeader {
	float: left;
	width: 100%;
	padding: 15px 0px;
	box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.2);
	position: fixed;
	left: 0px;
	right: 0px;
	top: 0px;
	background: #ffffff;
	/*z-index: 9999999;*/
	z-index: 999;

	&.affix {
		padding: 5px 0px;

		.logo {
			img {
				max-height: 50px;
			}
		}

		.vo-nav-bar {
			padding-top: 6px;
		}

		.topRight {
			width: auto;
			padding-top: 10px;

			.phone,
			.email {
				width: auto;

				.dropdown-menu {
					position: absolute;
					box-shadow: inherit;
					padding: 5px 10px;
					display: none;
					text-align: center !important;
					margin: 0;
					max-width: 240px;
					border-radius: 0 !Important;
				}
			}

			.phone {
				float: left;
				margin: 0px;
				cursor: pointer;
				position: relative;

				a {
					display: none;
				}

				&:hover {
					a {
						display: block;
						position: absolute;
						right: 0px;
						top: 100%;
						width: 150px;
						padding: 7px 10px;
						background: #ffffff;
						border: solid 1px #008a97;
						text-align: center;
						border-radius: 6px;
					}
				}
			}

			.email {
				float: left;
				margin: 0px;
				cursor: pointer;
				position: relative;

				a {
					display: none;
				}

				&:hover {
					a {
						display: block;
						position: absolute;
						right: 0px;
						top: 100%;
						width: 300px;
						padding: 7px 10px;
						background: #ffffff;
						border: solid 1px #008a97;
						text-align: right;
						border-radius: 6px;
					}
				}
			}

			.input-group.search {
				float: left;
				cursor: pointer;
				position: relative;
				display: none;

				.searchField {
					display: none;
				}

				&:hover {
					.searchField {
						display: block;
						position: absolute;
						right: 0px;
						top: 100%;
						width: 300px;
						padding: 7px 10px;
						background: #ffffff;
						border: solid 1px #008a97;
						text-align: right;
					}
				}
			}


			@media screen and (max-width: 767px) {
				margin: 0px 60px 0px 0px;
			}
		}
	}

	.logo {
		float: left;
	}

	.topRight {
		float: right;
		width: 25%;
		font-size: 16px;
		color: #008a97;
		line-height: 24px;

		.dropdown-menu {
			position: relative;
			border: none;
			box-shadow: inherit;
			padding: 0;

			@media (min-width:768px) {
				display: block;
			}

			@media (max-width:767px) {
				position: absolute;
				right: 0;
				top: 100%;
				padding: 7px 10px;
				background: #fff;
				border: 1px solid #008a97;
				text-align: center;
				border-radius: 0;
			}
		}

		a {
			color: #008a97;
			font-weight: 700;
			text-decoration: none;


			&:hover {
				color: #225ca8;
			}
		}

		.fa {
			position: absolute;
			border: solid 1px #008a97;
			width: 32px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border-radius: 50%;
			margin-right: 5px;
			left: 0px;
		}

		.phone,
		.email {
			margin-bottom: 12px;
			padding-left: 42px;
			min-height: 32px;
			position: relative;
			float: left;
			margin: 0 0 5px;
			cursor: pointer;
			position: relative;

			@media (min-width:768px) {
				width: 100%;
			}

			a {
				line-height: 30px;
			}
		}

		.phone {
			.fa-phone {
				transform: rotate(90deg);
			}
		}

		.search {
			display: block;

			.form-control {
				float: left;
				width: 80%;
				color: #008a97;
				border: solid 1px #008a97;
				box-shadow: none;
				border-radius: 0px;
			}

			.input-group-btn {
				float: left;
				width: 32px;
				margin-right: 10px;

				.btn {
					background: #ffffff;
					border: solid 1px #008a97;
					width: 32px;
					height: 32px;
					line-height: 30px;
					color: #008a97;
					text-align: center;
					font-weight: normal;
					padding: 0px;
					transform: rotate(90deg);
					border-radius: 50%;

					&:focus,
					&:hover {
						background: #ffffff;
						box-shadow: none;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.topRight {
			font-size: 14px;
		}
	}

	@media screen and (max-width: 989px) {
		.topRight {
			width: 45%;
		}
	}

	@media screen and (max-width: 767px) {
		.logo {
			img {
				width: 100%;
				max-height: 55px;
			}

		}

		.phone a,
		.email a {
			display: none;
		}

		.email {
			.dropdown-menu {
				width: 230px !important;
			}
		}

		.topRight {
			margin: 20px 0px 5px 0px;
			width: 143px;

			.input-group.search {
				display: none;
			}
		}
	}

}

.vo-nav-bar {
	float: right;
	padding-right: 25px;
	padding-top: 84px;

	a {
		float: left;
		display: block;
		color: #008a97;
		text-align: center;
		padding: 14px 16px;
		text-decoration: none;
	}

	.active {
		color: #225ca8;
	}

	.icon {
		display: none;
	}

	.dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.dropdown {
		overflow: visible;

		.dropdown-toggle {
			background: #ffffff;
			font-size: 16px;
			color: #008a97;
			border: none;
			outline: none;
			padding: 10px 20px 10px 18px;
			font-family: inherit;
			margin: 0;
			font-weight: 600;

			&:hover {
				background: #225ca8;
				color: #ffffff;
			}

			.fa-caret-down {
				margin-left: 8px;
			}
		}

		&:hover {
			.dropdown-toggle {
				background: #4b81be;
				color: #FFF;
			}
		}
	}

	.dropdown-menu {
		display: none;
		position: absolute;
		background-color: #4b81be;
		min-width: 160px;
		padding: 8px 0px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;

		a {
			background-color: #4b81be;
			color: #fff;
			float: none;
			font-size: 15px;
			padding: 6px 30px 6px 18px;
			text-decoration: none;
			display: block;
			text-align: left;
			font-weight: 600;

			&:hover {
				background: #f39200;
				color: #ffffff;
			}

			img {
				width: 30px;
				margin: 0 5px 0 0;
			}
		}

	}

	.dropdown-menu {
		border-radius: 0;
		box-shadow: inherit;
		margin: 0;
		border: none;
		min-width: 220px;
	}

	a:hover,
	.dropdown:hover .dropdown-toggle {}

	.dropdown-menu a:hover {}


	.dropdown:hover {}

	.dropdown:hover .dropdown-menu {
		@media (min-width:767px) {
			display: block;
		}
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.dropdown .dropdown-toggle {
			/*display: none;*/
		}

		a.icon {
			float: right;
			display: block;
		}
	}

	@media (min-width: 769px) and (max-width: 989px) {
		a.icon {
			float: right;
			display: none;
		}
	}

	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}

	@media screen and (max-width: 1200px) {
		padding-right: 15px;

		.dropdown {
			.dropdown-toggle {
				padding: 10px 15px 10px 13px;
			}
		}
	}

	@media (min-width: 769px) and (max-width: 1199px) {
		padding-right: 15px;
		padding: 20px 0 10px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		padding: 0px;
		margin-top: 15px;


		a.icon {
			background: #008a97;
			font-size: 24px;
			color: #ffffff;
			text-align: right;
			padding: 6px 15px;
			position: absolute;
			right: 12px;
			top: 25px;
		}

		.mobNavigation {
			display: none;
			position: absolute;
			width: 100%;
			left: 0;
			top: 100%;
			box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
			z-index: 999;
		}

		&.opened {
			.mobNavigation {
				display: block;
				background: #fff;
			}
		}

	}


}

.mainHeader.affix {
	.vo-nav-bar {
		@media screen and (max-width: 989px) {
			margin: 0px;
			padding: 3px 0px 0px 0px;
			right: 0px;
			position: static;

			&.opened {
				.mobNavigation {
					margin-top: 0;
				}
			}

			a.icon {
				position: absolute;
				top: 8px;
				right: 10px;
				width: auto;
			}
		}
	}

}


@media screen and (max-width: var(--navbarbreak)) {
	.vo-nav-bar.opened {
		z-index: 9999;
	}


	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .dropdown {
		float: left;
		width: 100%;
	}

	.dropdown {

		&.open {
			.dropdown-menu {
				display: block !Important;
				z-index: 5;
			}

			i.fas {
				transform: rotate(180deg);
			}

			.dropdown-menu {
				display: block;
				width: 100%;
			}
		}
	}

	.dropdown-backdrop {
		display: none;
	}

	.vo-nav-bar.opened .dropdown-menu {
		width: 100%;
		display: none;
	}

	.vo-nav-bar.opened .dropdown .dropdown-toggle {
		display: block;
		width: 100%;
		text-align: left;
	}
}